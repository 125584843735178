import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Page } from '../../base/Page'
import { useNavigate } from 'react-router-dom'
import { Product, ProductPricing } from '../../model/Product'
import DisplayImage from './ProductImageDisplay'
import { format } from 'date-fns'
import { useProducts } from '../../base/useDbHooks'
import { useProductPricing } from '../../base/useDbProductPricingHooks'
import { DataSnapshot } from 'firebase/database'
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'

const columnHelper = createColumnHelper<Product>()

const columns = [
  columnHelper.accessor('images', {
    header: 'Image',
    cell: (info) => {
      const images = info.getValue()
      return images ? <DisplayImage imagePath={images[0]} /> : null
    },
  }),
  columnHelper.accessor('productId', {
    header: 'Product Details',
    cell: (info) => {
      const product = info.row.original
      return (
        <div className='text-sm'>
          <div className='text-xs text-gray-500'>{product.productId}</div>
          <div className='text-gray-700 font-medium'>{product.productNameEn}</div>
          <div className='text-gray-500'>{product.category}</div>
        </div>
      )
    },
  }),
  columnHelper.accessor('taxRate', {
    header: 'Tax Rate',
    cell: (info) => <div className='text-gray-700'>{info.renderValue()}%</div>,
  }),
  columnHelper.accessor('createdDate', {
    header: 'Pricing',
    cell: (info) => <Pricings product={info.row.original} />,
  }),
]

export default function Products() {
  const { products } = useProducts()
  const navigate = useNavigate()

  const table = useReactTable({
    data: products,
    columns: [
      ...columns,
      {
        header: 'Actions',
        cell: (info: CellContext<Product, number>) => (
          <button
            className='px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600'
            onClick={() => navigate(`/products/${info?.row?.getValue('productId')}`)}
          >
            Edit
          </button>
        ),
      },
    ],
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Page title='Products'>
      <div className='flex justify-between mb-4'>
        <h1 className='text-lg font-bold'>Product List</h1>
        <button
          type='button'
          className='px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600'
          onClick={() => navigate('/products/add')}
        >
          Add Product
        </button>
      </div>
      <div className='overflow-x-auto bg-white rounded shadow'>
        <Table className='w-full border-collapse border border-gray-200'>
          <Thead>
            <Tr className='bg-gray-100'>
              {table.getHeaderGroups().map((headerGroup) =>
                headerGroup.headers.map((header) => (
                  <Th key={header.id} className='border px-4 py-2 text-left text-sm font-semibold'>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </Th>
                )),
              )}
            </Tr>
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id} className='hover:bg-gray-50'>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id} className='border px-4 py-2 text-sm'>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </Page>
  )
}

function Pricings({ product }: { product: Product }) {
  const { productPricingSnapshots } = useProductPricing(product.productId)
  return (
    <div className='space-y-1'>
      {productPricingSnapshots?.map((productPricingSnapshot: DataSnapshot, index) => {
        const pricing = productPricingSnapshot.val() as ProductPricing
        const isOutOfStock =
          Number(pricing.stock) <= 0 ||
          (pricing.expireDate && new Date(pricing.expireDate) < new Date())
        const stockClass = isOutOfStock ? 'text-red-500 font-bold' : ''
        const expireDate = pricing.expireDate
          ? format(new Date(Number(pricing.expireDate)), 'PPp')
          : ''
        return (
          <div key={index} className='text-gray-700'>
            <span className={`${stockClass}`}>{pricing.unit} - </span>
            <span className={`${stockClass}`}>{pricing.price}円 - </span>
            <span className={`${stockClass}`}>{pricing.stock} </span>
            {expireDate && <span className={`${stockClass}`}>- {expireDate}</span>}
          </div>
        )
      })}
    </div>
  )
}
