import { getDatabase, ref, set } from 'firebase/database'
import { useContext, useEffect, useState } from 'react'
import { useList, useListVals, useObjectVal } from 'react-firebase-hooks/database'
import { DB } from '../DB'
import { auth, firebaseApp } from '../firebase'
import { Category } from '../model/Category'
import { Product, ProductPricing } from '../model/Product'
import { DeliveryCharge, DeliveryLocation, OtherSettings } from '../model/Settings'
import { DBContext } from './DbContext'
import { useAuthState } from 'react-firebase-hooks/auth'

const database = getDatabase(firebaseApp)

export const checkAdmin = (user: any): boolean => {
  return (
    user?.email === 'baskaran.c@gmail.com' ||
    user?.email === 'kabilan.baskaran.in@gmail.com' ||
    user?.email === 'dharani.baskaran.jp@gmail.com' ||
    user?.email === 'rajeswari.baskar@gmail.com' ||
    user?.email === 'raji@royalbakes.jp'
  )
}

export const useCheckAdmin = () => {
  const [user, loading] = useAuthState(auth)
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    if (!loading && user) {
      const _isAdmin = checkAdmin(user)
      setIsAdmin(_isAdmin)
    }
  })

  return { isAdmin }
}

export const useCategories = () => {
  const [categoriesSnapshots] = useList(ref(database, DB.categories))
  const [categories, setCategories] = useState<Category[]>([])

  useEffect(() => {
    if (categoriesSnapshots) {
      const cats = categoriesSnapshots.map((s) => s?.toJSON() as Category)
      cats.sort((a, b) => a.displayOrder - b.displayOrder)

      setCategories(cats)
    }
  }, [categoriesSnapshots])

  return { categories }
}

export const useCategory = (categoryKey?: string) => {
  const { categories } = useContext(DBContext)
  const [category, setCategory] = useState<Category>()

  useEffect(() => {
    if (categoryKey && categories) {
      setCategory(categories.find((c) => c.category === categoryKey))
    }
  }, [categoryKey, categories])

  return { category }
}

export const useProducts = () => {
  const [snapshotsProduct] = useList(ref(database, DB.products))
  const [products, setProducts] = useState<Product[]>([])

  useEffect(() => {
    if (snapshotsProduct) {
      const ps = snapshotsProduct.map((s) => s?.toJSON() as Product)
      const sps = ps.sort((a, b) => a.category?.localeCompare(b.category ?? ''))
      setProducts(sps)
    }
  }, [snapshotsProduct])

  return { products }
}

export const useProduct = (id?: string) => {
  const { products } = useContext(DBContext)
  const [product, setProduct] = useState<Product>()

  useEffect(() => {
    if (products) {
      setProduct(products?.find((p) => p.productId === id))
    }
  }, [products])

  return { product }
}

export const useProductsByCategory = () => {
  const { products } = useContext(DBContext)
  const [productsByCategory, setProductsByCategory] = useState<any>([])

  useEffect(() => {
    if (products) {
      const groupByCategory = products.reduce((group: any, product) => {
        const { category } = product
        group[category] = group[category] ?? []
        group[category].push(product)
        return group
      }, {})

      // sort by product nameEn
      for (const category in groupByCategory) {
        groupByCategory[category].sort((a: Product, b: Product) =>
          a.productNameEn.localeCompare(b.productNameEn),
        )
      }

      setProductsByCategory(groupByCategory)
    }
  }, [products])

  return { productsByCategory }
}

export const useProductsByCategoryId = (category?: string) => {
  const { productsByCategory } = useProductsByCategory()
  const [products, setProducts] = useState<Product[]>([])

  useEffect(() => {
    if (productsByCategory && category) {
      setProducts(productsByCategory[category])
    }
  }, [category, productsByCategory])

  return { products }
}

export const useDeliveryLocations = () => {
  const [deliveryLocations] = useListVals<DeliveryLocation>(ref(database, DB.deliveryLocations))
  return { deliveryLocations }
}

export const useDeliveryCharges = () => {
  const [deliveryCharges] = useListVals<DeliveryCharge>(ref(database, DB.deliveryCharges))
  deliveryCharges?.sort((a, b) => a.range - b.range)
  return { deliveryCharges }
}

export const useOtherSettings = () => {
  const [otherSettings] = useObjectVal<OtherSettings>(ref(database, DB.settings))
  return { otherSettings }
}

export const useGetAllPricing = () => {
  const [pricings] = useListVals<any>(ref(database, DB.productPricing))
  const [prices, setPrices] = useState<ProductPricing[]>([])

  useEffect(() => {
    let prices: any[] = []
    pricings?.forEach((p) => {
      prices = [...prices, ...Object.values(p)]
    })
    setPrices(prices)
  }, [pricings])

  return { prices }
}

export const useUpdateUserBalance = (userId: string) => {
  const [userBalance] = useObjectVal<number>(ref(database, DB.userBalance(userId)))

  const updateBalance = (newBalance: number) => {
    set(ref(database, DB.userBalance(userId)), newBalance)
  }

  return { userBalance, updateBalance }
}
