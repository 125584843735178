import { useEffect, useState } from 'react'
import { useProductsByCategory } from '../../base/useDbHooks'
import { useDisplayCategories, useProductPricing } from '../../base/useDbProductPricingHooks'
import { Category } from '../../model/Category'
import { Product, ProductPricing } from '../../model/Product'
import { DataSnapshot } from 'firebase/database'

export function StockAndPriceUpdate() {
  const { displayCategories } = useDisplayCategories()
  const { productsByCategory } = useProductsByCategory()
  const [showNoStockOnly, setShowNoStockOnly] = useState(false)

  if (!displayCategories || !productsByCategory) {
    return null
  }

  const handleToggleNoStockOnly = () => {
    setShowNoStockOnly(!showNoStockOnly)
  }

  return (
    <div className='container mx-auto py-8'>
      <h1 className='uppercase text-2xl font-bold text-center mb-6'>Stock Update</h1>
      <div className='flex items-center justify-center mb-6'>
        <input
          id='noStockOnly'
          type='checkbox'
          className='mr-2'
          checked={showNoStockOnly}
          onChange={handleToggleNoStockOnly}
        />
        <label htmlFor='noStockOnly' className='text-lg font-medium text-gray-700'>
          Show No Stock Items Only
        </label>
      </div>
      {displayCategories.map((category) => {
        const products = productsByCategory[category.category]
        if (!products) return null

        return (
          <DisplayCategory
            key={category.category}
            category={category}
            products={products}
            showNoStockOnly={showNoStockOnly}
          />
        )
      })}
    </div>
  )
}

function DisplayCategory({
  category,
  products,
  showNoStockOnly,
}: {
  category: Category
  products: Product[]
  showNoStockOnly: boolean
}) {
  return (
    <section className='mb-6'>
      <h2 className='uppercase text-xl font-bold mb-4'>{category.nameEn}</h2>
      {products.map((product) => (
        <DisplayProduct
          key={product.productId}
          product={product}
          showNoStockOnly={showNoStockOnly}
        />
      ))}
    </section>
  )
}

function DisplayProduct({
  product,
  showNoStockOnly,
}: {
  product: Product
  showNoStockOnly: boolean
}) {
  const { productPricingSnapshots } = useProductPricing(product.productId)

  if (!productPricingSnapshots || productPricingSnapshots.length === 0) {
    return null
  }

  return (
    <div className='product mb-4 p-4 border border-gray-300 rounded-lg'>
      <h3 className='font-semibold text-lg mb-2'>{product.productNameEn}</h3>
      {productPricingSnapshots.map((snapshot) => (
        <DisplayPricing
          key={snapshot.key}
          productPricingSnapshot={snapshot}
          showNoStockOnly={showNoStockOnly}
        />
      ))}
    </div>
  )
}

function DisplayPricing({
  productPricingSnapshot,
  showNoStockOnly,
}: {
  productPricingSnapshot: DataSnapshot
  showNoStockOnly: boolean
}) {
  const initialPricing = productPricingSnapshot.val() as ProductPricing
  const { updatePricing } = useProductPricing()
  const [stock, setStock] = useState(initialPricing.stock)
  const [price, setPrice] = useState(initialPricing.price)

  if (showNoStockOnly && initialPricing.stock > 0) {
    return null
  }

  const handleStockUpdate = (newStock: number) => {
    if (newStock !== stock) {
      setStock(newStock)
      updatePricingSnapshot({ stock: newStock, price })
    }
  }

  const handlePriceUpdate = (newPrice: number) => {
    if (newPrice !== price) {
      setPrice(newPrice)
      updatePricingSnapshot({ stock, price: newPrice })
    }
  }

  const updatePricingSnapshot = (updatedValues: { stock: number; price: number }) => {
    const updatedPricing = { ...initialPricing, ...updatedValues }
    updatePricing(productPricingSnapshot, updatedPricing)
  }

  const isStockAvailable = stock > 0

  return (
    <div
      className={`pricing-row grid grid-cols-4 gap-4 items-center p-4 border rounded-md ${
        !isStockAvailable ? 'bg-red-100' : 'bg-white'
      }`}
    >
      <span className='text-lg font-medium text-gray-800'>{initialPricing.unit}</span>
      <div className='price-control flex items-center gap-4'>
        <div className='flex items-center gap-2'>
          <input
            type='number'
            className='price-input w-20 px-2 py-1 text-center bg-gray-100 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400'
            value={price}
            min={0}
            onChange={(e) => handlePriceUpdate(getAsNumber(e.target.value))}
          />
          <span className='text-base text-gray-600'>Yen</span>
        </div>
      </div>
      <div className='stock-control flex items-center gap-4'>
        <div className='flex items-center gap-2'>
          <button
            className='btn-decrement bg-gray-200 hover:bg-gray-300 text-gray-600 rounded-l h-10 w-10 flex items-center justify-center'
            onClick={() => handleStockUpdate(Math.max(stock - 1, 0))}
          >
            −
          </button>
          <input
            type='number'
            className='stock-input w-20 px-2 py-1 text-center bg-gray-100 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400'
            value={stock}
            min={0}
            onChange={(e) => handleStockUpdate(getAsNumber(e.target.value))}
          />
          <button
            className='btn-increment bg-gray-200 hover:bg-gray-300 text-gray-600 rounded-r h-10 w-10 flex items-center justify-center'
            onClick={() => handleStockUpdate(stock + 1)}
          >
            +
          </button>
        </div>
      </div>
    </div>
  )
}

function getAsNumber(value: any): number {
  const parsed = parseFloat(value)
  return isNaN(parsed) ? 0 : parsed
}
