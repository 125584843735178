import { Card } from 'flowbite-react'
import { Page } from '../../base/Page'
import {
  useDisplayCategories,
  useProductPricing,
  useProductsByCategory,
} from '../../base/useDbProductPricingHooks'
import { Product, ProductPricing } from '../../model/Product'
import { useRef } from 'react'

export function WhatsAppMessage() {
  const { displayCategories } = useDisplayCategories()
  const { productsByCategory } = useProductsByCategory()
  const contentRef = useRef<HTMLDivElement>(null)

  const handleCopy = () => {
    if (contentRef.current) {
      const range = document.createRange()
      range.selectNodeContents(contentRef.current)
      const selection = window.getSelection()
      if (selection) {
        selection.removeAllRanges()
        selection.addRange(range)
      }
      document.execCommand('copy')
      selection?.removeAllRanges()
      alert('Content copied to clipboard!')
    }
  }

  return (
    <Page>
      <Card>
        <div ref={contentRef}>
          {displayCategories?.map((category) => (
            <div key={category.category} className='cat_container dark:text-white'>
              <ProductDetails
                category={category.nameEn}
                products={productsByCategory[category.category] || []}
              />
              <br />
            </div>
          ))}
          <br />
          <div>Please order above 10000円 or more. for free delivery.</div>
        </div>
        <button
          onClick={handleCopy}
          className='mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'
        >
          Select All & Copy
        </button>
      </Card>
    </Page>
  )
}

function ProductDetails({ category, products }: { category: string; products: Product[] }) {
  if (products.length === 0) {
    return null
  }
  return (
    <>
      <div className='uppercase'>*{category}*</div>
      <ol className='product_results '>
        {products.map((product: Product, index: number) => (
          <ProductDetail key={product.productId} product={product} index={index + 1} />
        ))}
      </ol>
    </>
  )
}

function ProductDetail({ product, index }: { product: Product; index: number }) {
  const { productPricingSnapshots } = useProductPricing(product.productId)

  if (!productPricingSnapshots || productPricingSnapshots.length === 0) {
    return null
  }

  const productPricings = productPricingSnapshots
    .map((snapshot) => snapshot.val() as ProductPricing)
    .filter((pricing) => pricing.stock > 0)

  if (productPricings.length === 0) {
    return null
  }

  return (
    <li key={product.productId}>
      {index}. {product.productNameEn} -{' '}
      {productPricings.map((pricing) => (
        <span key={pricing.pricingId}>
          {pricing.unit} - {pricing.price}円{' '}
        </span>
      ))}
    </li>
  )
}
