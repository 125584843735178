import React from 'react';
import { Styles } from '../base/Styles';
import { Page } from '../base/Page';
import { Card } from 'flowbite-react';
import { useCategories } from '../base/useDbHooks';
import { useNavigate } from 'react-router-dom';

// Define the type for a Category
interface Category {
  category: string;
  nameEn: string;
  nameJp: string;
  displayMenu: boolean;
  displayOrder: number;
}

export default function Categories() {
  const { categories } = useCategories();
  const navigate = useNavigate();

  // Component for Table Row
  const TableRow: React.FC<{ category: Category }> = ({ category }) => (
    <tr>
      <td className="py-2 border-b">{category.category}</td>
      <td className="py-2 border-b">{category.nameEn}</td>
      <td className="py-2 border-b">{category.nameJp}</td>
      <td className="py-2 border-b text-center">
        {category.displayMenu ? (
          <span className="text-green-600 font-bold">Yes</span>
        ) : (
          'No'
        )}
      </td>
      <td className="py-2 border-b text-center">{category.displayOrder}</td>
      <td className="py-2 border-b text-center">
        <button
          className={Styles.button.link}
          onClick={() => navigate(`/settings/categories/${category.category}`)}
        >
          Edit
        </button>
      </td>
    </tr>
  );

  return (
    <Page title="Categories">
      <div className="mb-4">
        <button
          className={Styles.button.default}
          onClick={() => navigate('/settings/categories/add')}
        >
          Add Category
        </button>
      </div>
      <Card>
        <table className="table-auto w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="py-2 border-b-2 text-left">Category</th>
              <th className="py-2 border-b-2 text-left">Name En</th>
              <th className="py-2 border-b-2 text-left">Name JP</th>
              <th className="py-2 border-b-2 text-center">Menu Display</th>
              <th className="py-2 border-b-2 text-center">Display Order</th>
              <th className="py-2 border-b-2 text-center">Edit</th>
            </tr>
          </thead>
          <tbody>
            {categories?.length ? (
              categories.map((category: Category) => (
                <TableRow key={category.category} category={category} />
              ))
            ) : (
              <tr>
                <td colSpan={6} className="py-4 text-center text-gray-500">
                  No categories available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Card>
    </Page>
  );
}
