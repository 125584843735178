import { Page } from '../../base/Page'
import { Product, ProductPricing } from '../../model/Product'
import { useEffect, useState } from 'react'
import { Button, Modal } from 'flowbite-react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  calculateBalance,
  useAddOrderItem,
  useOrder,
  useOrderItems,
  useRemoveOrderItem,
  useUpdateOrderItem,
  useUpdateOrderTotals,
  useUpdatePaidAmount,
} from '../../base/useDbOrders'
import { ProductOrder } from '../../model/Order'
import {
  useGetAllPricing,
  useOtherSettings,
  useProducts,
  useUpdateUserBalance,
} from '../../base/useDbHooks'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { format } from 'date-fns'

export default function OrderView() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { order } = useOrder(id ?? '')
  useUpdateOrderTotals(id ?? '')
  const { otherSettings } = useOtherSettings()

  const date = order?.orderDate ? format(new Date(Number(order.orderDate)), 'PPp') : ''

  return (
    <Page title='Order View'>
      <div className='flex flex-col gap-1 sm:w-full  md:w-3/5 bg-gray-200'>
        <div className='flex gap-2  flex-wrap justify-evenly'>
          <div>{id}</div>
          <span className=' font-bold'>{date} </span>
          <span className='text-lg font-bold'>{order?.orderStatus} </span>
          <div>
            <span className='text-lg font-bold'>
              ¥
              {new Intl.NumberFormat('ja-JP', { maximumFractionDigits: 0 }).format(
                order?.totalAmount ?? 0,
              )}
            </span>
          </div>
        </div>

        <div className='flex flex-1 gap-2 justify-evenly'>
          <b>{order?.deliveryName}</b>, {order?.deliveryPhone}, {order?.deliveryAddress}{' '}
        </div>
      </div>
      <div className='w-full md:w-3/5 bg-white rounded p-2'>
        <OrderProducts />
      </div>
    </Page>
  )

  function OrderProducts() {
    const { id } = useParams()
    const { orderProducts } = useOrderItems(id)

    console.log('orderProducts', orderProducts)

    if (!id) return null

    const updateAddQuantity = (productOrder: ProductOrder) => {
      productOrder.quantity = productOrder.quantity + 1
      useUpdateOrderItem(productOrder)
    }

    const updateSubtractQuantity = (productOrder: ProductOrder) => {
      const quantity = productOrder.quantity - 1
      if (quantity < 1) return
      productOrder.quantity = quantity
      useUpdateOrderItem(productOrder)
    }

    const removeProduct = (productOrder: ProductOrder) => {
      useRemoveOrderItem(productOrder)
    }

    return (
      <>
        <div className='flex flex-wrap items-center justify-evenly bg-gray-100 '>
          <AddProduct />
          <CancelButton />
          <PaidButton />
        </div>
        <Table className='table-auto m-3 rounded-lg'>
          <Thead className='border-b'>
            <Tr>
              <Th className='w-52 p-3'>Product</Th>
              <Th>Price</Th>
              <Th className='w-12 text-center'>Quantity</Th>
              <Th className='w-20 text-right'>Total</Th>
              <Th className='w-10'></Th>
            </Tr>
          </Thead>
          <Tbody>
            {orderProducts?.map((p) => {
              return (
                <>
                  <Tr className='border-b'>
                    <Td className='p-3 '>
                      <a
                        className='cursor-pointer'
                        href={'/product/' + p?.productId}
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/product/' + p?.productId)
                        }}
                      >
                        {p.thumbImage && <img src={p.thumbImage} className='rounded w-14' />}
                      </a>
                    </Td>
                    <Td className='p-3 flex flex-col justify-center items-center cursor-pointer'>
                      <a
                        className='font-semibold '
                        href={'/product/' + p?.productId}
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/product/' + p?.productId)
                        }}
                      >
                        {p.nameEn}
                      </a>
                      <div className='font-thin'>{p.unit}</div>
                    </Td>
                    <Td className='text-right'>
                      <span className='font-thin text-xs'>¥</span>
                      {new Intl.NumberFormat('ja-JP').format(p.price)}
                    </Td>
                    <Td className='text-right '>
                      <div className='px-3 flex items-baseline gap-2'>
                        <div
                          className='flex justify-center cursor-pointer rounded-full bg-red-200 w-6'
                          onClick={() => {
                            updateSubtractQuantity(p)
                          }}
                        >
                          -
                        </div>{' '}
                        {p.quantity}
                        <div
                          className='flex justify-center cursor-pointer  rounded-full bg-green-300 w-6'
                          onClick={() => {
                            updateAddQuantity(p)
                          }}
                        >
                          +
                        </div>
                      </div>
                    </Td>
                    <Td className='text-right'>
                      <span className='font-thin text-xs'>¥</span>
                      {new Intl.NumberFormat('ja-JP').format(p.quantity * p.price)}
                    </Td>
                    <Td>
                      <div
                        className='flex justify-center cursor-pointer rounded-full bg-red-500 w-6'
                        onClick={() => {
                          removeProduct(p)
                        }}
                      >
                        X
                      </div>
                    </Td>
                  </Tr>
                </>
              )
            })}
          </Tbody>
          <tfoot>
            {order?.taxes &&
              Array.from(order?.taxes?.entries())?.map((tax) => {
                if (tax.length < 2) return null
                const { amount, rate } = tax[1]
                return (
                  <>
                    <tr>
                      <td className='text-right text-sm' colSpan={3}>
                        tax {rate}%
                      </td>
                      <td colSpan={2} className='text-right text-sm'>
                        <span className='font-thin text-xs'>¥</span>
                        {new Intl.NumberFormat('ja-JP', { maximumFractionDigits: 0 }).format(
                          amount,
                        )}
                      </td>
                    </tr>
                  </>
                )
              })}
            {order?.deliveryCharge && order?.deliveryCharge > 0 && (
              <tr>
                <td className='text-right text-sm pt-5' colSpan={3}>
                  deliveryCharge
                </td>
                <td colSpan={2} className='text-right pt-6'>
                  <span className='font-thin text-xs'>¥</span>
                  <span className='font-semibold text-lg'>
                    {new Intl.NumberFormat('ja-JP', { maximumFractionDigits: 0 }).format(
                      order?.deliveryCharge,
                    )}
                  </span>
                </td>
              </tr>
            )}
            {order?.deliveryCharge &&
              order?.deliveryCharge > 0 &&
              order?.deliveryChargeTax &&
              order?.deliveryChargeTax > 0 && (
                <tr>
                  <td className='text-right text-sm pt-6' colSpan={3}>
                    deliveryChargeTax {otherSettings?.shippingTax}%
                  </td>
                  <td colSpan={2} className='text-right text-sm pt-6'>
                    <span className='font-thin text-xs'>¥</span>
                    {new Intl.NumberFormat('ja-JP', { maximumFractionDigits: 0 }).format(
                      order?.deliveryChargeTax,
                    )}
                  </td>
                </tr>
              )}
            <tr>
              <td></td>
              <td></td>
              <td className='text-right'>Total</td>
              <td colSpan={2} className='text-right font-semibold'>
                <span className='font-thin text-xs'>¥</span>
                <span className='font-semibold text-2xl'>
                  {order?.totalAmount && new Intl.NumberFormat('ja-JP').format(order?.totalAmount)}
                </span>
              </td>
            </tr>
          </tfoot>
        </Table>
      </>
    )
  }

  function CancelButton() {
    // Add cancel button and confirmation modal
    const [cancelModal, setCancelModal] = useState(false)
    const { updateCancelled } = useUpdatePaidAmount(order?.orderId || '')
    const [cancelReason, setCancelReason] = useState('')

    const handleCancel = () => {
      setCancelModal(false)
      updateCancelled(cancelReason || 'No reason given')
      navigate('/orders')
    }

    return (
      <>
        <div className='flex justify-end'>
          <Button
            color='failure'
            onClick={() => {
              setCancelModal(true)
            }}
          >
            Cancel
          </Button>
        </div>
        <Modal show={cancelModal} onClose={() => setCancelModal(false)}>
          <Modal.Header>Confirm Cancel</Modal.Header>
          <Modal.Body>
            <div className='space-y-6'>
              <p className='text-base leading-relaxed text-gray-500 dark:text-gray-400'>
                Are you sure you want to cancel this order?
              </p>
            </div>
            <div className='flex flex-col space-y-2'>
              <label className='block text-sm font-medium text-gray-700 dark:text-gray-200'>
                Reason
              </label>
              <textarea
                className='block w-full px-3 py-2 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                rows={3}
                placeholder='Enter reason'
                value={cancelReason}
                onChange={(e) => {
                  setCancelReason(e.target.value)
                }}
              ></textarea>
            </div>
          </Modal.Body>
          <Modal.Footer className='flex justify-between'>
            <Button
              color='warning'
              onClick={() => {
                setCancelModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              color='failure'
              onClick={() => {
                setCancelModal(false)
                handleCancel()
              }}
            >
              Confirm Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  function PaidButton() {
    // Add paid button and confirmation modal
    const [paidModal, setPaidModal] = useState(false)
    const [paidMethod, setPaidMethod] = useState<'cash' | 'paypay'>('cash')
    const [paidAmount, setPaidAmount] = useState(0)
    const [balance, setBalance] = useState<number>(order?.totalAmount || 0)
    const { updatePaid } = useUpdatePaidAmount(order?.orderId || '')
    const { userBalance } = useUpdateUserBalance(order?.userId || '')
    const navigate = useNavigate()

    useEffect(() => {
      if (order?.totalAmount) {
        setBalance(calculateBalance(order.totalAmount, paidAmount, userBalance ?? 0))
      }
    }, [paidAmount, userBalance, order?.totalAmount])

    const handlePaid = () => {
      // add paid method and amount
      setPaidModal(false)
      updatePaid(paidMethod, paidAmount)
      navigate('/orders')
    }

    return (
      <>
        <div className='flex justify-end'>
          <Button
            onClick={() => {
              setPaidModal(true)
            }}
          >
            Paid
          </Button>
        </div>
        <Modal show={paidModal} onClose={() => setPaidModal(false)}>
          <Modal.Header>Confirm Paid</Modal.Header>
          <Modal.Body>
            <div className='space-y-6'>
              <p className='text-base leading-relaxed text-gray-500 dark:text-gray-400'>
                Are you sure you want to mark this order as paid?
              </p>
            </div>
            {/* add Paid method cash or paypay and amount */}

            <div className='flex flex-col space-y-2'>
              <label className='block text-sm font-medium text-gray-700 dark:text-gray-200'>
                Paid Method
              </label>
              <select
                className='block w-full px-3 py-2 text-base text-gray-700 placeholder-gray-400 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:border-gray-600 dark:focus:ring-indigo-400 dark:focus:border-indigo-400 dark:bg-gray-700 dark:text-gray-300 dark:placeholder-gray-500'
                defaultValue='Select'
                onChange={(e) => {
                  setPaidMethod(e.target.value as 'cash' | 'paypay')
                }}
              >
                <option value='cash'>Cash</option>
                <option value='paypay'>PayPay</option>
              </select>
            </div>
            {/* show paypay QR code when PayPay option selected */}
            {paidMethod === 'paypay' && (
              <div className='flex flex-col space-y-2'>
                <label className='block text-sm font-medium text-gray-700 dark:text-gray-200'>
                  PayPay QR Code
                </label>
                <img
                  src='https://www.paypay.ne.jp/qr/qr.php?qrdata=00020101021129370016JP.CO.PAYPAY.QR000000000000000000520400005303392540510.005802JP5913PayPay%20QR%20Code6009Tokyo6304E0C8'
                  alt='PayPay QR Code'
                />
              </div>
            )}
            {/* show user balance */}
            {userBalance && (
              <div className='flex '>
                <label className='block text-sm font-medium text-gray-700 dark:text-gray-200'>
                  User Balance
                </label>
                <div className='flex space-y-2'>
                  <span
                    className={
                      'block text-3xl font-medium dark:text-gray-200 ' +
                      (userBalance < 0 ? 'text-red-500' : 'text-green-700')
                    }
                  >
                    <span className='font-thin text-xs'>¥</span>
                    {userBalance && new Intl.NumberFormat('ja-JP').format(userBalance)}
                  </span>
                </div>
              </div>
            )}
            {/* add Paid amount */}
            {paidMethod === 'cash' && (
              <>
                <div className='flex '>
                  <label className='block text-sm font-medium text-gray-700 dark:text-gray-200'>
                    Total Amount
                  </label>
                  <div className='flex space-y-2'>
                    <span className='block text-3xl font-medium text-red-500 dark:text-gray-200'>
                      <span className='font-thin text-xs'>¥</span>
                      {order?.totalAmount &&
                        new Intl.NumberFormat('ja-JP').format(order?.totalAmount)}
                    </span>
                  </div>
                </div>
                <div className='flex flex-col space-y-2'>
                  <label className='block text-sm font-medium text-gray-700 dark:text-gray-200'>
                    Paid Amount
                  </label>
                  <input
                    type={'number'}
                    className='block w-full px-3 py-2 text-base text-gray-700 placeholder-gray-400 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:border-gray-600 dark:focus:ring-indigo-400 dark:focus:border-indigo-400 dark:bg-gray-700 dark:text-gray-300 dark:placeholder-gray-500'
                    placeholder='Paid Amount'
                    onChange={(e) => {
                      setPaidAmount(e.target.value ? parseInt(e.target.value) : 0)
                    }}
                  />
                </div>
                {
                  // show balance
                }
                <div className='flex '>
                  <label className='block text-sm font-medium text-gray-700 dark:text-gray-200'>
                    Balance
                  </label>
                  <div className='flex space-y-2'>
                    <span
                      className={
                        'block text-3xl font-medium  dark:text-gray-200 ' +
                        (balance < 0 ? 'text-red-500' : 'text-green-700')
                      }
                    >
                      <span className='font-thin text-xs'>¥</span>
                      {order?.totalAmount && new Intl.NumberFormat('ja-JP').format(balance)}
                    </span>
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer className='flex justify-between'>
            <Button
              color='failure'
              onClick={() => {
                setPaidModal(false)
              }}
            >
              Cancel
            </Button>
            {
              // paid partial amount
            }
            {paidMethod === 'cash' && paidAmount !== order?.totalAmount && (
              <Button
                color='info'
                onClick={() => {
                  setPaidModal(false)
                  handlePaid()
                }}
              >
                Paid Partial
              </Button>
            )}
            {paidMethod === 'cash' && paidAmount === order?.totalAmount && (
              <Button
                color='success'
                onClick={() => {
                  setPaidModal(false)
                  handlePaid()
                }}
              >
                Confirm
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  function AddProduct() {
    const { products } = useProducts()
    const { prices } = useGetAllPricing()
    const [productsWithPrice, setProductsWithPrice] = useState<any[]>([])

    useEffect(() => {
      const productsWithPrice = prices?.map((p) => {
        const product = products?.find((pp) => pp.productId === p.productId)
        const newObj = {
          ...product,
          price: p?.price,
          id: p.productId,
          pricingId: p.pricingId,
          unit: p?.unit,
          name: product?.productNameEn,
        }
        return newObj
      })
      setProductsWithPrice(productsWithPrice as any)
    }, [products, prices])

    const handleOnSearch = (str: string, results: any[]) => {
      // onSearch will have as the first callback parameter
      // the string searched and for the second the results.
      console.log('handleOnSearch', str, results)
    }

    const handleOnHover = (result: any) => {
      // the item hovered
      console.log(result)
    }

    const handleOnSelect = (item: any) => {
      if (order) {
        const productOrder = new ProductOrder()
        productOrder.additionDate = new Date().toISOString()
        productOrder.productId = item.productId
        productOrder.pricingId = item.pricingId
        productOrder.nameEn = item.productNameEn
        productOrder.nameJp = item.productNameJp
        productOrder.unit = item.unit
        productOrder.price = item.price
        productOrder.quantity = 1
        productOrder.comments = ''
        productOrder.thumbImage = item.images ? item.images['0'] : ''
        productOrder.taxRate = item.taxRate
        productOrder.userId = order.userId
        productOrder.orderId = order.orderId

        console.log('productOrder', productOrder)
        useAddOrderItem(productOrder)
      }
    }

    const handleOnFocus = () => {
      console.log('Focused')
    }

    const formatResult = (item: Product & ProductPricing & { id: string; name: string }) => {
      return (
        <>
          <div className='flex items-center'>
            {item.images && <img src={item.images[0]} className='w-8 h-auto' />}
            <div className='flex flex-wrap'>
              <span className='font-thin text-xs'>{item.name}</span> -
              <span className='font-semibold'>{item.unit}</span>
              <div className='p-0'>
                <span className='font-thin text-xs'>¥</span>
                <span className='font-semibold'>{item.price}</span>
              </div>
            </div>
          </div>
        </>
      )
    }
    return (
      <div className='flex items-center gap-1 w-4/5 md:w-1/4 pb-2'>
        <div>Add</div>
        <div className='w-4/5'>
          <ReactSearchAutocomplete
            items={productsWithPrice}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            formatResult={formatResult}
          />
        </div>
      </div>
    )
  }
}
