import { useState } from 'react';
import { useProductsByCategory } from '../../base/useDbHooks';
import { useDisplayCategories, useProductPricing } from '../../base/useDbProductPricingHooks';
import { Category } from '../../model/Category';
import { Product, ProductPricing } from '../../model/Product';
import { DataSnapshot } from 'firebase/database';

export function PriceUpdate() {
  const { displayCategories } = useDisplayCategories();
  const { productsByCategory } = useProductsByCategory();

  if (!displayCategories || !productsByCategory) {
    return null;
  }

  return (
    <div className="container mx-auto py-8 px-6">
      <h1 className="text-3xl font-semibold text-center mb-8">Price Update</h1>
      {displayCategories.map((category) => {
        const products = productsByCategory[category.category];
        if (!products) return null;

        return (
          <DisplayCategory
            key={category.category}
            category={category}
            products={products}
          />
        );
      })}
    </div>
  );
}

function DisplayCategory({ category, products }: { category: Category; products: Product[] }) {
  return (
    <section className="mb-8">
      <h2 className="text-2xl font-bold mb-6">{category.nameEn}</h2>
      {products.map((product) => (
        <DisplayProduct key={product.productId} product={product} />
      ))}
    </section>
  );
}

function DisplayProduct({ product }: { product: Product }) {
  const { productPricingSnapshots } = useProductPricing(product.productId);

  if (!productPricingSnapshots || productPricingSnapshots.length === 0) {
    return null;
  }

  return (
    <div className="product mb-6 p-6 border border-gray-300 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">{product.productNameEn}</h3>
      {productPricingSnapshots.map((snapshot) => (
        <DisplayPricing
          key={snapshot.key}
          productPricingSnapshot={snapshot}
        />
      ))}
    </div>
  );
}

function DisplayPricing({ productPricingSnapshot }: { productPricingSnapshot: DataSnapshot }) {
  const initialPricing = productPricingSnapshot.val() as ProductPricing;
  const { updatePricing } = useProductPricing();
  const [price, setPrice] = useState(initialPricing.price);

  const handlePriceUpdate = (newPrice: number) => {
    if (newPrice !== price) {
      setPrice(newPrice);
      const updatedPricing = { ...initialPricing, price: newPrice };
      updatePricing(productPricingSnapshot, updatedPricing);
    }
  };

  return (
    <div className="flex items-center justify-between mb-4">
      <span className="text-lg">{initialPricing.unit}</span>
      <div className="flex items-center gap-4">
        <span className="text-lg font-medium">{price} Yen</span>
        <input
          type="number"
          className="w-16 text-center bg-gray-200 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          value={price}
          min={0}
          onChange={(e) => handlePriceUpdate(getAsNumber(e.target.value))}
        />
      </div>
    </div>
  );
}

function getAsNumber(value: any): number {
  const parsed = parseInt(value, 10);
  return isNaN(parsed) ? 0 : parsed;
}
