import { useSearchParams } from 'react-router-dom'
import { useDbReportsInvoiceList } from '../../base/useDbReports'
import { useState, useEffect } from 'react'

// Helper function to group data
function groupByDate(data: any[], key: 'daily' | 'monthly') {
  return data.reduce((acc: any, item) => {
    const date = new Date(item.invoiceDate || item.orderDate)
    const groupKey =
      key === 'daily'
        ? date.toLocaleDateString() // e.g., "12/26/2024"
        : `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}` // e.g., "2024-12"

    if (!acc[groupKey]) {
      acc[groupKey] = { items: [], totalAmount: 0, totalTax: 0 }
    }
    acc[groupKey].items.push(item)
    acc[groupKey].totalAmount += item.totalAmount
    acc[groupKey].totalTax += item.taxAmount

    return acc
  }, {})
}

// Table component for grouped data
function GroupedReportTable({
  title,
  groupedData,
  groupBy,
}: {
  title: string
  groupedData: Record<string, any>
  groupBy: 'daily' | 'monthly'
}) {
  return (
    <div className='pt-10'>
      <div className='font-bold text-lg'>{title}</div>
      {Object.entries(groupedData).map(([group, { items, totalAmount, totalTax }]) => (
        <div key={group} className='mb-6'>
          <div className='font-bold text-base mb-2'>
            {groupBy === 'daily' ? `Date: ${group}` : `Month: ${group}`}
          </div>
          <table className='w-full border-collapse border border-gray-300 mb-4'>
            <thead>
              <tr>
                <th className='border border-gray-300 p-2'>Date</th>
                <th className='border border-gray-300 p-2'>Invoice No</th>
                <th className='border border-gray-300 p-2 text-right'>Amount</th>
                <th className='border border-gray-300 p-2 text-right'>Tax</th>
              </tr>
            </thead>
            <tbody>
              {items.map((order: any) => (
                <tr key={order.invoiceId} className='border'>
                  <td className='border border-gray-300 p-2'>
                    {new Date(order.invoiceDate || order.orderDate).toLocaleDateString()}
                  </td>
                  <td className='border border-gray-300 p-2'>{order.invoiceId}</td>
                  <td className='border border-gray-300 p-2 text-right'>{order.totalAmount}</td>
                  <td className='border border-gray-300 p-2 text-right'>{order.taxAmount}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={2} className='text-right font-bold border-t border-gray-300 p-2'>
                  Total
                </td>
                <td className='text-right font-bold border-t border-gray-300 p-2'>{totalAmount}</td>
                <td className='text-right font-bold border-t border-gray-300 p-2'>{totalTax}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      ))}
    </div>
  )
}

export function SalesReportsInvoiceList() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedYear, setSelectedYear] = useState<string>(
    searchParams.get('year') || new Date().getFullYear().toString(),
  )

  const { invoices, directInvoices } = useDbReportsInvoiceList(selectedYear)

  useEffect(() => {
    searchParams.set('year', selectedYear)
    setSearchParams(searchParams)
  }, [selectedYear, searchParams, setSearchParams])

  const groupedDailyInvoices = groupByDate(invoices, 'daily')
  const groupedMonthlyInvoices = groupByDate(invoices, 'monthly')
  const groupedDailyDirectInvoices = groupByDate(directInvoices, 'daily')
  const groupedMonthlyDirectInvoices = groupByDate(directInvoices, 'monthly')

  return (
    <div>
      <div className='flex flex-col mx-auto w-full items-center border-2 pb-2 bg-gray-50'>
        <label className='mb-2'>
          Report Year: {selectedYear}
          <div>
            Show report for year:
            <select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              className='ml-2 border rounded px-2 py-1'
            >
              {Array.from({ length: 10 }, (_, i) => i + 2023).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </label>
      </div>

      <GroupedReportTable
        title={`Daily Online Sales Reports (${selectedYear})`}
        groupedData={groupedDailyInvoices}
        groupBy='daily'
      />

      <GroupedReportTable
        title={`Monthly Online Sales Reports (${selectedYear})`}
        groupedData={groupedMonthlyInvoices}
        groupBy='monthly'
      />

      <GroupedReportTable
        title={`Daily Shop Sales Reports (${selectedYear})`}
        groupedData={groupedDailyDirectInvoices}
        groupBy='daily'
      />

      <GroupedReportTable
        title={`Monthly Shop Sales Reports (${selectedYear})`}
        groupedData={groupedMonthlyDirectInvoices}
        groupBy='monthly'
      />
    </div>
  )
}
