import React, { ForwardedRef, forwardRef, HTMLAttributes } from 'react'

interface FieldProps extends HTMLAttributes<HTMLInputElement> {
  disabled?: boolean
  error?: string
  label?: string
  name: string
  type?: string
  maxLength?: number
  placeholder?: string
}
type FieldRef = ForwardedRef<never>

// eslint-disable-next-line react/display-name
export const InputText = forwardRef(
  (
    { disabled, error, label, name, type = 'text', maxLength, className, placeholder, ...rest }: FieldProps,
    ref: FieldRef,
  ) => {
    return (
      <>
        <div className=''>
          <label
            htmlFor={name}
            className={
              error
                ? 'block mb-2 text-sm font-medium text-red-700 dark:text-red-500'
                : 'block mb-2 text-sm font-medium text-gray-900 dark:text-white'
            }
          >
            {label}
          </label>
          <input
            type={type}
            id={name}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            aria-describedby='helper-text-explanation'
            className={
              error
                ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-300 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400 ' +
                  className
                : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ' +
                  className
            }
            ref={ref}
            maxLength={maxLength}
            {...rest}
          />
          {error && <p className='mt-2 text-sm text-red-600 dark:text-red-500'>{error}!</p>}
        </div>
      </>
    )
  },
)
