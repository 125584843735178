import { useSearchParams } from 'react-router-dom'
import { useDbReports } from '../../base/useDbReports'
import { useState } from 'react'

export function SalesReports() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [year, setYear] = useState(searchParams.get('year') ?? new Date().getFullYear().toString())
  // const [year, setYear] = useState('2024')

  const {
    invoicesPerDate,
    invoiceTotalPerDate,
    invoiceTotalTaxPerDate,
    invoiceTotalAmount,
    invoiceTotalTax,
    directInvoicesPerDate,
    directInvoiceTotalPerDate,
    directInvoiceTotalTaxPerDate,
    directInvoiceTotalAmount,
    directInvoiceTotalTax,
  } = useDbReports(year)

  const invoiceTotals = Array.from(invoiceTotalPerDate, ([key, value]) => ({
    date: key,
    amount: value,
  }))
  invoiceTotals.sort((a, b) => a.date.localeCompare(b.date))

  const directInvoiceTotals = Array.from(directInvoiceTotalPerDate, ([key, value]) => ({
    date: key,
    amount: value,
  }))
  directInvoiceTotals.sort((a, b) => a.date.localeCompare(b.date))

  return (
    <div className='container mx-auto px-4'>
      <div className='flex flex-col mx-auto w-full items-center border-2 pb-2 bg-gray-50'>
        <div className='text-xl font-semibold'>
          Report Year: {searchParams.get('year') ?? new Date().getFullYear()}
        </div>
        <div className='mt-2'>
          Show report for year:
          <select
            onChange={(e) => {
              searchParams.set('year', e.target.value)
              setSearchParams(searchParams)
            }}
            className='ml-2 p-2 border rounded'
          >
            {Array.from({ length: 10 }, (_, i) => i + 2023).map((y) => (
              <option key={y} value={y} selected={searchParams.get('year') === y.toString()}>
                {y}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className='font-bold text-lg mt-10'>Daily Online Sales Reports ({year})</div>

      {/* Invoice Total Per Date */}
      <table className='table-auto w-full mt-4 border-collapse border border-gray-300'>
        <thead>
          <tr className='bg-gray-100 text-left'>
            <th className='py-2 px-4 border-b'>Date</th>
            <th className='py-2 px-4 border-b text-right'>Amount</th>
            <th className='py-2 px-4 border-b text-right'>Total Tax</th>
            <th className='py-2 px-4 border-b'>Invoice No</th>
            <th className='py-2 px-4 border-b text-right'>Total</th>
            <th className='py-2 px-4 border-b text-right'>Tax</th>
          </tr>
        </thead>
        <tbody>
          {invoiceTotalPerDate.size > 0 &&
            invoiceTotals.map(({ date, amount }) => (
              <>
                <tr key={'invTotal' + date} className='border-b border-t-2 hover:bg-gray-50'>
                  <td className='py-2 px-4'>{date}</td>
                  <td className='py-2 px-4 text-right'>{amount}</td>
                  <td className='py-2 px-4 text-right'>{invoiceTotalTaxPerDate.get(date)}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                {invoicesPerDate.get(date)?.map((order) => (
                  <tr
                    key={'invTotal' + date + 'details' + order.invoiceId}
                    className='border-b hover:bg-gray-50'
                  >
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className='py-2 px-4' key={order.invoiceId}>
                      {order.invoiceId}
                    </td>
                    <td className='py-2 px-4 text-right' key={order.totalAmount}>
                      {order.totalAmount}
                    </td>
                    <td className='py-2 px-4 text-right' key={order.taxAmount}>
                      {order.taxAmount}
                    </td>
                  </tr>
                ))}
              </>
            ))}
        </tbody>
        <tfoot>
          <tr className='bg-gray-100'>
            <td className='py-2 px-4 text-right font-bold'>Total</td>
            <td className='py-2 px-4 text-right font-bold'>{invoiceTotalAmount}</td>
            <td className='py-2 px-4 text-right font-bold'>{invoiceTotalTax}</td>
            <td className='py-2 px-4'></td>
            <td className='py-2 px-4'></td>
            <td className='py-2 px-4'></td>
          </tr>
        </tfoot>
      </table>

      <div className='font-bold text-lg pt-10'>Daily Shop Sales Reports ({year})</div>

      {/* Direct Invoice Total Per Date */}
      <table className='table-auto w-full mt-4 border-collapse border border-gray-300'>
        <thead>
          <tr className='bg-gray-100 text-left'>
            <th className='py-2 px-4 border-b'>Date</th>
            <th className='py-2 px-4 border-b text-right'>Amount</th>
            <th className='py-2 px-4 border-b text-right'>Total Tax</th>
            <th className='py-2 px-4 border-b'>Invoice No</th>
            <th className='py-2 px-4 border-b text-right'>Total</th>
            <th className='py-2 px-4 border-b text-right'>Tax</th>
          </tr>
        </thead>
        <tbody>
          {directInvoiceTotalPerDate.size > 0 &&
            directInvoiceTotals.map(({ date, amount }) => (
              <>
                <tr key={'invDirTotal' + date} className='border-b border-t-4 hover:bg-gray-50'>
                  <td className='py-2 px-4'>{date}</td>
                  <td className='py-2 px-4 text-right'>{amount}</td>
                  <td className='py-2 px-4 text-right'>{directInvoiceTotalTaxPerDate.get(date)}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                {directInvoicesPerDate.get(date)?.map((order) => (
                  <tr
                    key={'invDirTotal' + date + 'details' + order.invoiceId}
                    className='border-b hover:bg-gray-50'
                  >
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className='py-2 px-4' key={order.invoiceId}>
                      {order.invoiceId}
                    </td>
                    <td className='py-2 px-4 text-right' key={order.totalAmount}>
                      {order.totalAmount}
                    </td>
                    <td className='py-2 px-4 text-right' key={order.taxAmount}>
                      {order.taxAmount}
                    </td>
                  </tr>
                ))}
              </>
            ))}
        </tbody>
        <tfoot>
          <tr className='bg-gray-100'>
            <td className='py-2 px-4 text-right font-bold'>Total</td>
            <td className='py-2 px-4 text-right font-bold'>{directInvoiceTotalAmount}</td>
            <td className='py-2 px-4 text-right font-bold'>{directInvoiceTotalTax}</td>
            <td className='py-2 px-4'></td>
            <td className='py-2 px-4'></td>
            <td className='py-2 px-4'></td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}
