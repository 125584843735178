import { useEffect, useState } from 'react'
import { useProductsByCategory } from '../../base/useDbHooks'
import { useDisplayCategories, useProductPricing } from '../../base/useDbProductPricingHooks'
import { Category } from '../../model/Category'
import { Product, ProductPricing } from '../../model/Product'
import { DataSnapshot } from 'firebase/database'

export function StockUpdate() {
  const { displayCategories } = useDisplayCategories()
  const { productsByCategory } = useProductsByCategory()

  if (!displayCategories || !productsByCategory) {
    return null
  }

  return (
    <div className='container mx-auto py-8'>
      <h1 className='uppercase text-2xl font-bold text-center mb-6'>Stock Update</h1>
      {displayCategories.map((category) => {
        const products = productsByCategory[category.category]
        if (!products) return null

        return <DisplayCategory key={category.category} category={category} products={products} />
      })}
    </div>
  )
}

function DisplayCategory({ category, products }: { category: Category; products: Product[] }) {
  return (
    <section className='mb-6'>
      <h2 className='uppercase text-xl font-bold mb-4'>{category.nameEn}</h2>
      {products.map((product) => (
        <DisplayProduct key={product.productId} product={product} />
      ))}
    </section>
  )
}

function DisplayProduct({ product }: { product: Product }) {
  const { productPricingSnapshots } = useProductPricing(product.productId)

  if (!productPricingSnapshots || productPricingSnapshots.length === 0) {
    return null
  }

  return (
    <div className='product mb-4 p-4 border border-gray-300 rounded-lg'>
      <h3 className='font-semibold text-lg mb-2'>{product.productNameEn}</h3>
      {productPricingSnapshots.map((snapshot) => (
        <DisplayPricing key={snapshot.key} productPricingSnapshot={snapshot} />
      ))}
    </div>
  )
}

function DisplayPricing({ productPricingSnapshot }: { productPricingSnapshot: DataSnapshot }) {
  const initialPricing = productPricingSnapshot.val() as ProductPricing
  const { updatePricing } = useProductPricing()
  const [stock, setStock] = useState(initialPricing.stock)

  const handleStockUpdate = (newStock: number) => {
    if (newStock !== stock) {
      setStock(newStock)
      const updatedPricing = { ...initialPricing, stock: newStock }
      updatePricing(productPricingSnapshot, updatedPricing)
    }
  }

  const isStockAvailable = stock > 0

  return (
    <div className={`pricing-row grid grid-cols-3 gap-4 ${!isStockAvailable ? 'bg-red-100' : ''}`}>
      <span>{initialPricing.unit}</span>
      <span className='text-right font-medium'>{initialPricing.price} Yen</span>
      <div className='stock-control flex items-center gap-2'>
        <button
          className='btn-decrement bg-gray-300 hover:bg-gray-400 text-gray-600 rounded-l h-10 w-12'
          onClick={() => handleStockUpdate(Math.max(stock - 1, 0))}
        >
          −
        </button>
        <input
          type='number'
          className='stock-input w-16 text-center bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-400'
          value={stock}
          min={0}
          onChange={(e) => handleStockUpdate(getAsNumber(e.target.value))}
        />
        <button
          className='btn-increment bg-gray-300 hover:bg-gray-400 text-gray-600 rounded-r h-10 w-12'
          onClick={() => handleStockUpdate(stock + 1)}
        >
          +
        </button>
      </div>
    </div>
  )
}

function getAsNumber(value: any): number {
  const parsed = parseInt(value, 10)
  return isNaN(parsed) ? 0 : parsed
}
